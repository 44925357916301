import Navbar from "./components/Navbar";
import "./index.css";

import Hero from "./pages/Hero";

import Realisations from "./pages/Realisations";
import Blockchain from "./pages/Blockchain";
import Services from "./pages/Services";

import Barre from "./components/Barre";
import IconBar from "./components/IconBar";

function App() {
  // lock && unlock scroll
  document.body.style.overflow = "hidden";

  const unlock = () => {
    document.body.style.overflow = "auto";
  };

  setTimeout(unlock, 5000);
  return (
    <div className="bg-black justify-center font-Haptik_Medium overflow-hidden overflow-x-hidden">
      <div className="intro">
        <Barre />
      </div>

      <div className="transition overflow-hidden overflow-x-hidden">
        <Navbar />

        <Hero />
        <Realisations />
        <Services />
        <Blockchain />

        <IconBar />
      </div>
    </div>
  );
}

export default App;
