import React from "react";

import { BiEnvelope } from "react-icons/bi";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";

import "../index.css";
const IconBar = () => {
  return (
    <div className={``}>
      <div className="flex  justify-center items-center z-50 w-full text-violet-300 lg:h-[10vh] h-[12.5vh] ">
        <div className="flex w-16 h-16 justify-center items-center rounded-[100%] ">
          {" "}
          <a
            href="https://www.instagram.com/effiwebagency/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="h-8 w-8 hover:w-10 hover:h-10 " />
          </a>
        </div>
        <div className="flex w-16 h-16 justify-center items-center  ">
          <a
            href="mailto:contact@effiwebagency.com"
            target="_blank"
            rel="noreferrer"
          >
            <BiEnvelope className="h-8 w-8 hover:w-10 hover:h-10 " />
          </a>
        </div>
        <div className="flex w-16 h-16 justify-center items-center  ">
          <a
            href="https://api.whatsapp.com/send?phone=971526676880"
            target="_blank"
            rel="noreferrer"
          >
            <FaWhatsapp className="h-8 w-8 hover:w-10 hover:h-10 " />{" "}
          </a>
        </div>
      </div>

      <div className="text-[10px] font-light text-zinc-100 text-center pb-[1vh]">
        {" "}
        Copyright 2022 - EFFI Web Agency – Tous droits réservés
      </div>
    </div>
  );
};

export default IconBar;
