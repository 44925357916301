import React, { useState } from "react";
import "../index.css";

const Services = () => {
  const [isWebsite, setIsWebsite] = useState(false);
  const [isDesign, setIsDesign] = useState(false);
  const [isMarketing, setIsMarketing] = useState(false);
  const [isMarque, setIsMarque] = useState(false);

  return (
    <div id="services" className=" text-white bg-black ">
      <div className="h-[1px] w-[50%]  border-t border-zinc-600 m-auto "></div>
      <div className=" flex items-center text-center justify-center text-[20px] sm:text-[25px] md:text-[35px] lg:text-[50px] xl:text-[65px] text-zinc-100 pt-[5vh] pb-[5vh] xl:pt-[10vh] xl:pb-[10vh] ">
        {" "}
        <h4>
          Boostez votre visibilité<span className=" text-violet-300 ">.</span>
          <div className="text-[60%] md:text-[40%]  text-violet-300">
            {" "}
            Devenez inoubliable<span className="text-white">.</span>
          </div>
        </h4>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 w-[100vw] h-[80vh] md:h-[40vh]  gap-1 text-4xl xl:text-6xl ">
        <div className=" flex h-[20vh] w-full bg-white hover:bg-black text-violet-300 cursor-pointer">
          {" "}
          <div
            className="w-full h-full items-center justify-center flex"
            onClick={() => {
              setIsWebsite((current) => !current);
            }}
          >
            WEBSITE
          </div>
        </div>
        <div
          className="flex justify-center items-center h-[20vh] w-full bg-violet-300 text-white hover:bg-black cursor-pointer "
          onClick={() => {
            setIsDesign((current) => !current);
          }}
        >
          {" "}
          DESIGN{" "}
        </div>
        <div
          className="flex justify-center items-center h-[20vh] w-full bg-white hover:bg-black text-violet-300 md:bg-violet-300 md:text-white cursor-pointer"
          onClick={() => {
            setIsMarketing((current) => !current);
          }}
        >
          {" "}
          MARKETING
        </div>
        <div
          className="flex justify-center items-center h-[20vh] w-full  bg-violet-300 md:bg-white md:text-violet-300 hover:bg-black cursor-pointer"
          onClick={() => {
            setIsMarque((current) => !current);
          }}
        >
          {" "}
          DIGITALISATION{" "}
        </div>
      </div>

      {/* 
    MODAL POUR WEBSITE 
    MODAL POUR WEBSITE 
    MODAL POUR WEBSITE  */}

      <div
        className={
          isWebsite
            ? `relative md:mt-[-40vh] mt-[-80vh] md:h-[41vh] h-[81vh] text-md lg:text-lg xl:text-xl bg-black w-full text-center justify-center flex items-center  opacity-95 flex-col p-5 cursor-pointer`
            : "hidden"
        }
        onClick={() => {
          setIsWebsite((current) => !current);
        }}
      >
        <div>
          {" "}
          Vous souhaitez développer votre{" "}
          <span className="text-violet-300">présence en ligne</span> et
          atteindre de nouveaux clients ? <br />
          Un site <span className="text-violet-300">web professionnel</span> est
          la clé de votre réussite ! <br /> <br />
          Chez <span className="text-violet-300">EFFI</span>, nous mettons notre
          <span className="text-violet-300"> expertise</span> en conception de
          sites web au service de votre entreprise pour créer une{" "}
          <span className="text-violet-300">vitrine</span> en ligne qui vous
          distinguera de vos concurrents. <br />
          Nous proposons des{" "}
          <span className="text-violet-300">solutions sur mesure</span>, allant
          des sites <span className="text-violet-300">e-commerce </span>
          aux <span className="text-violet-300">sites complexes</span>, pour
          vous aider à <span className="text-violet-300">fidéliser</span> vos
          clients et à
          <span className="text-violet-300"> optimiser vos ventes</span>. <br />
          <br />
          En tant que{" "}
          <span className="text-violet-300">spécialistes du numérique</span>,
          nous sommes également en mesure de vous orienter vers{" "}
          <span className="text-violet-300">les meilleures solutions</span> de
          gestion de contenu pour votre projet.{" "}
        </div>
      </div>

      {/* 
    MODAL POUR DESIGN
    MODAL POUR DESIGN
    MODAL POUR DESIGN  */}

      <div
        className={
          isDesign
            ? `relative md:mt-[-40vh] mt-[-80vh] md:h-[41vh] h-[81vh] text-md lg:text-lg xl:text-xl bg-black w-full text-center flex items-center justify-center opacity-95 flex-col p-5 cursor-pointer`
            : "hidden"
        }
        onClick={() => {
          setIsDesign((current) => !current);
        }}
      >
        <div>
          {" "}
          Vous cherchez à donner vie à votre projet grâce à un{" "}
          <span className="text-violet-300">design</span> percutant et cohérent
          ?
          <br /> <span className="text-violet-300">Nous </span>sommes là pour
          vous <span className="text-violet-300">aider</span> ! <br />
          <br />
          Que vous ayez besoin d'une{" "}
          <span className="text-violet-300">palette de couleurs</span>, d'une
          idée de <span className="text-violet-300">concept visuel</span> ou de
          <span className="text-violet-300"> designs professionnels </span>, de
          <span className="text-violet-300"> graphismes</span>, de{" "}
          <span className="text-violet-300"> modélisations</span>
          <span className="text-violet-300"> 3D</span>, de{" "}
          <span className="text-violet-300"> motion design</span>, d'
          <span className="text-violet-300">effets spéciaux </span>pour vos{" "}
          <span className="text-violet-300">spots publicitaires</span>, nous
          mettons notre <span className="text-violet-300">créativité</span> et
          notre <span className="text-violet-300">expertise</span> à votre
          disposition pour créer une{" "}
          <span className="text-violet-300">identité visuelle </span>forte et
          cohérente . <br />
          <br />
          Nous travaillons en étroite collaboration avec vous pour comprendre
          vos <span className="text-violet-300"> objectifs</span> et vos besoins
          afin de développer des{" "}
          <span className="text-violet-300">solutions</span> de design sur
          mesure qui vous permettront de vous démarquer de vos{" "}
          <span className="text-violet-300">concurrents</span>.{" "}
        </div>
      </div>

      {/* 
    MODAL POUR DESIGN
    MODAL POUR DESIGN
    MODAL POUR DESIGN  */}

      <div
        className={
          isMarketing
            ? `relative md:mt-[-40vh] mt-[-80vh] md:h-[41vh] h-[81vh] text-md lg:text-lg xl:text-xl bg-black w-full text-center justify-center flex items-center  opacity-95 flex-col p-5 cursor-pointer`
            : "hidden"
        }
        onClick={() => {
          setIsMarketing((current) => !current);
        }}
      >
        <div>
          {" "}
          <span className="text-violet-300">EFFI</span> c'est l'agence{" "}
          <span className="text-violet-300">digitale </span>
          experte en marketing qui s'occupe de toutes les{" "}
          <span className="text-violet-300">problématiques</span> liées à votre
          entreprise.
          <br />
          <br /> Nous mettons en place des{" "}
          <span className="text-violet-300">stratégies</span> de marketing{" "}
          <span className="text-violet-300">efficaces</span> et utilisons des
          techniques de promotion et de publicité pour atteindre votre{" "}
          <span className="text-violet-300">public cible</span>.
          <br /> <br />
          Notre équipe est <span className="text-violet-300">experte</span> dans
          la création de campagnes publicitaires en ligne, la{" "}
          <span className="text-violet-300">gestion</span> de la présence de
          votre entreprise sur les réseaux sociaux, le{" "}
          <span className="text-violet-300">développement</span> de stratégies
          de contenu et de marketing de contenu, le travail sur le référencement
          naturel <span className="text-violet-300">(SEO)</span> et la gestion
          de campagnes de publicité en ligne payantes{" "}
          <span className="text-violet-300">(SEA)</span>.
          <br /> <br />
          Nous sommes là pour vous aider à atteindre vos objectifs de{" "}
          <span className="text-violet-300">croissance </span>
          en proposant des <span className="text-violet-300">solutions</span> de
          marketing <span className="text-violet-300">adaptées</span> à votre
          entreprise.
        </div>
      </div>

      {/* 
    MODAL POUR DIGITALISATION
    MODAL POUR DIGITALISATION
    MODAL POUR DIGITALISATION  */}

      <div
        className={
          isMarque
            ? `relative md:mt-[-40vh] mt-[-80vh] md:h-[41vh] h-[81vh] text-md lg:text-lg xl:text-xl bg-black w-full text-center justify-center flex items-center  opacity-95 flex-col p-5 cursor-pointer`
            : "hidden"
        }
        onClick={() => {
          setIsMarque((current) => !current);
        }}
      >
        <div>
          {" "}
          Chez <span className="text-violet-300">EFFI</span>, nous sommes fiers
          de proposer une{" "}
          <span className="text-violet-300">solution complète</span> pour la
          <span className="text-violet-300"> digitalisation</span> de votre
          entreprise. <br />
          <br />
          Nous avons pour mission de transformer votre activité en un business
          <span className="text-violet-300"> moderne</span> et{" "}
          <span className="text-violet-300">interactif</span>, en phase avec les
          exigences de l'<span className="text-violet-300">ère numérique</span>.{" "}
          <br />
          <br />
          Nous nous occupons de tout, de la conception de votre{" "}
          <span className="text-violet-300">site Web</span> à la mise en place
          de stratégies de contenu attrayantes pour attirer de
          <span className="text-violet-300"> nouveaux clients</span>. <br />{" "}
          <br /> Nous sommes des professionnels{" "}
          <span className="text-violet-300"> compétents</span> et{" "}
          <span className="text-violet-300"> passionnés</span>, et nous nous
          engageons à vous fournir un service de qualité pour vous aider à
          atteindre vos objectifs de{" "}
          <span className="text-violet-300"> croissance</span>. <br />
          <br />
          Faites confiance à <span className="text-violet-300"> EFFI </span>pour
          <span className="text-violet-300"> digitaliser</span> votre entreprise
          et la propulser vers le{" "}
          <span className="text-violet-300"> succès</span>.{" "}
        </div>
      </div>
    </div>
  );
};

export default Services;
