import React from "react";

import "./csscard.css";
import globe from "../assets/logo/globe.png";
const CardGlobe = () => {
  return (
    <div className="box h-[100px] w-[100px] lg:h-[150px] lg:w-[150px] xl:h-[200px] xl:w-[200px] flex justify-center items-center">
      <div className="content flex items-center justify-center">
        {" "}
        <img
          src={globe}
          alt="Lancement"
          className="w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] xl:w-[100px] xl:h-[100px] relative z-40 "
        />{" "}
      </div>
    </div>
  );
};

export default CardGlobe;
