import React from "react";

import "./barre.css";

const Barre = () => {
  return (
    <div className="fixed h-[100vh] w-full z-50 overflow-hidden">
      <div className="absolute flex w-full h-full bg-black  ">
        <div className="boule_1 "></div>
        <div className="boule_2 "></div>
        <div className="boule_3"></div>
        <div className="boule_4"></div>
        <div className="boule_5"></div>
        <div className="boule_6"></div>
        <div className="boule_7"></div>
        <div className="boule_8"></div>
      </div>

      <div className="barre_gauche_1"></div>
      <div className="barre_gauche_2"></div>
      <div className="barre_gauche_3"></div>
      <div className="barre_gauche_4"></div>
      <div className="barre_gauche_5"></div>
      <div className="barre_gauche_6"></div>
      <div className="barre_gauche_7"></div>
      <div className="barre_gauche_8"></div>
      <div className="barre_gauche_9"></div>
      <div className="barre_gauche_10"></div>
      <div className="barre_gauche_11"></div>
      <div className="barre_gauche_12"></div>
      <div className="barre_gauche_13"></div>
      <div className="barre_gauche_14"></div>
      <div className="barre_gauche_15"></div>
      <div className="barre_gauche_16"></div>
      <div className="barre_gauche_17"></div>
      <div className="barre_gauche_18"></div>
      <div className="absolute flex w-full h-full items-center justify-center">
        <div className="big_boule absolute w-full"> </div>
      </div>
      <div className="div_boule flex absolute items-center justify-center h-full w-full">
        <div className="boule_animate_1"> </div>
      </div>

      <div className="div_boule flex absolute items-center justify-center h-full w-full">
        <div className="boule_animate_2"> </div>
      </div>

      <div className="make absolute flex justify-center items-center h-full w-full text-center">
        {" "}
        We <br className="sm:hidden" /> Make It <br className="sm:hidden" />
        Real{" "}
      </div>
      <div className="text-center flex flex-col justify-center items-center h-full w-full">
        <div className="what "> What </div>
        <div className="you"> You </div>
        <div className="think"> Imagine </div>
      </div>
    </div>
  );
};

export default Barre;
