import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";

import { Link } from "react-scroll";

import { MdOutlineClose } from "react-icons/md";
import logo from "../assets/EFFI.png";
import "./barre.css";
const Navbar = () => {
  const [nav, setNav] = useState(true);
  const hideNav = () => setNav(!nav);
  const [swapNav, setSwapNav] = useState("");

  // Navbar disappear

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  const scrollDirection = useScrollDirection();

  return (
    <div>
      <div
        className={`fixed lg:flex w-full h-[7.5vh] z-50 bg-black text-white font-semibold bg-opacity-90 ${
          scrollDirection === "down" ? "-top-[10vh]" : "top-0"
        }  0 transition-all duration-500 hidden `}
      >
        <div className="flex justify-between w-full items-center pl-[2.5vw] pr-[2.5vw] cursor-pointer">
          <div className=" w-8 h-8 lg:w-12 lg:h-12">
            <Link
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => {}}
              to="hero"
            >
              <img src={logo} alt="LOGO EFFI" />{" "}
            </Link>
          </div>

          <div
            id="MENU"
            className="flex gap-[5vh] font-bold text-[0.7vw]  justify-center items-center"
          >
            <button className={`flex  tracking-widest  stroke_hover`}>
              {" "}
              <Link
                to="realisations"
                activeclass="active"
                spy={true}
                smooth={true}
                duration={500}
              >
                NOS RÉALISATIONS
              </Link>{" "}
            </button>
            <button className={`flex  tracking-widest  stroke_hover`}>
              {" "}
              <Link
                to="services"
                activeclass="active"
                spy={true}
                smooth={true}
                duration={500}
              >
                {" "}
                NOS SERVICES
              </Link>
            </button>
            <button className={`flex  tracking-widest  stroke_hover`}>
              {" "}
              <Link
                to="blockchain"
                activeclass="active"
                spy={true}
                smooth={true}
                duration={500}
              >
                BLOCKCHAIN
              </Link>{" "}
            </button>{" "}
            <a
              href="https://api.whatsapp.com/send?phone=971526676880"
              className={`flex w-[11vw] border h-[6vh] items-center rounded-full overflow-hidden overflow-x-hidden justify-center tracking-widest hvr-bounce-to-right text-violet-300 border-violet-300`}
              target="_blank"
              rel="noreferrer"
            >
              CONTACT{" "}
            </a>{" "}
          </div>
        </div>
      </div>

      {/* MOBILE MENU */}
      <div
        className={`lg:hidden flex fixed w-full h-[7.5vh] justify-between items-center p-5 tracking-widest z-50 bg-black opacity-90 `}
      >
        <div className=" w-8 h-8 flex items-center">
          <Link
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => {}}
            to="hero"
          >
            <img src={logo} alt="LOGO EFFI" />{" "}
          </Link>
        </div>{" "}
        <div className="flex gap-5 justify-center items-center">
          {" "}
          <a
            href="https://api.whatsapp.com/send?phone=971526676880"
            target="_blank"
            rel="noreferrer"
            className="text-zinc-100 underline  underline-offset-8 relative  text-[12px]"
          >
            CONTACT{" "}
          </a>{" "}
          <div className={` ${swapNav} flex justify-center items-center`}>
            {nav ? (
              <GiHamburgerMenu
                className={`h-10 w-10 relative z-50 text-violet-300`}
                onClick={() => {
                  setNav((current) => !current);
                  setSwapNav("open_nav");
                }}
              />
            ) : (
              <MdOutlineClose
                className={`h-10 w-10    relative z-50 text-violet-300`}
                onClick={() => {
                  setNav((current) => !current);
                  setSwapNav("close_nav");
                }}
              />
            )}
          </div>
        </div>{" "}
      </div>

      {!nav ? (
        <ul
          id="MENU_MOBILE"
          className={
            nav
              ? "hidden"
              : `fixed w-full h-full nav_fluide flex flex-col items-center justify-center gap-12 text-4xl  text-zinc-100 z-40 bg-black bg-opacity-90`
          }
        >
          <li className="  home_fluide">
            {" "}
            <Link
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => {
                hideNav();
              }}
              to="hero"
            >
              {" "}
              HOME{" "}
            </Link>
          </li>

          <li className="  realisations_fluide">
            <Link
              spy={true}
              smooth={true}
              duration={500}
              to="realisations"
              onClick={() => {
                hideNav();
              }}
            >
              NOS RÉALISATIONS
            </Link>
          </li>
          <li className="  services_fluide">
            <Link
              spy={true}
              smooth={true}
              duration={500}
              to="services"
              onClick={() => {
                hideNav();
              }}
            >
              NOS SERVICES
            </Link>
          </li>

          <li className="  blockchain_fluide">
            {" "}
            <Link
              spy={true}
              smooth={true}
              duration={500}
              to="blockchain"
              onClick={() => {
                hideNav();
              }}
            >
              BLOCKCHAIN
            </Link>{" "}
          </li>
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

export default Navbar;
