import React from "react";
import { useState } from "react";
import amirel from "../assets/mockup-amirel.jpg";
import mobilier from "../assets/mockup-mobilier.jpg";
import bobamazing from "../assets/mobile-bobamazing.jpg";
import effi from "../assets/mockup-effi.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import miel from "../assets/realisations caroussel/3dmiel.png";
import bancfancy from "../assets/realisations caroussel/bancfancy.png";
import bigeyes from "../assets/realisations caroussel/bigeyes.png";
import chairoldschool from "../assets/realisations caroussel/chairoldschool.png";
import cosmeticsfruits from "../assets/realisations caroussel/cosmeticsfruits.png";
import designmiel from "../assets/realisations caroussel/designmiel.png";
import eggfancy from "../assets/realisations caroussel/eggfancy.png";
import eggfancywhite from "../assets/realisations caroussel/eggfancywhite.png";
import eiffel from "../assets/realisations caroussel/eiffel.png";
import endlessworld from "../assets/realisations caroussel/endlessworld.png";
import fancyburger from "../assets/realisations caroussel/fancyburger.png";
import fancycream from "../assets/realisations caroussel/fancycream.png";
import fruitsred from "../assets/realisations caroussel/fruitsred.png";
import goldenburger from "../assets/realisations caroussel/goldenburger.png";
import goldshop from "../assets/realisations caroussel/goldshop.png";
import logofostot from "../assets/realisations caroussel/logofostot.png";

import "../index.css";
import "swiper/css";

const Realisations = () => {
  const [bgAmirel, setBgAmirel] = useState("hidden");
  const [bgMobilier, setBgMobilier] = useState("hidden");
  const [bgBobamazing, setBgBobamazing] = useState("hidden");
  const [bgNeutral, setBgNeutral] = useState("");
  const [bgMockup, setBgMockup] = useState(effi);

  return (
    <div id="realisations" className=" text-white bg-black  ">
      {/* DIV PRINCIPALE  */}
      <div className="">
        {" "}
        <div className="h-[5vh] bg-black"> </div>
        <Swiper
          spaceBetween={0}
          slidesPerView={5}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {" "}
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={designmiel}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={logofostot}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={eiffel}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={goldenburger}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={cosmeticsfruits}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={eggfancywhite}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={bigeyes}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={chairoldschool}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
        </Swiper>{" "}
        <Swiper
          spaceBetween={0}
          slidesPerView={5}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {" "}
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={goldshop}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={miel}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={fruitsred}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={fancyburger}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={fancycream}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={eggfancy}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={endlessworld}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[20vw] w-[20vw] ">
              {" "}
              <img
                src={bancfancy}
                alt="Graphique réalisé entièrement"
                className="hover:opacity-50"
              />{" "}
            </div>
          </SwiperSlide>
        </Swiper>{" "}
      </div>
      <div className=" flex lg:flex-row flex-col text-zinc relative justify-center items-center pt-[5vh] pb-[5vh]  pl-[2.5vw] pr-[2.5vw]">
        {/* DIVISION SECONDAIRE  */}
        {/* DIVISION SECONDAIRE  */}
        <div className="flex flex-col lg:flex-row">
          {/* Affichage Premier au depart  */}
          {/* Affichage Premier au depart  */}
          {/* Affichage Premier au depart  */}
          <div
            className={`${bgNeutral} bg-black  h-[300px] w-[300px] md:h-[400px] md:w-[400px] lg:h-[350px] lg:w-[350px] xl:h-[500px] xl:w-[500px] rounded-[100%] border border-zinc-100 flex items-center justify-center `}
          >
            <div className="text-center relative flex flex-col items-center justify-center ">
              <div className="text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px]">
                {" "}
                OUR WORK<span className=" text-violet-300 ">.</span>
              </div>
              <div className="flex items-center flex-col text-[15px] md:text-[20px] lg:text-[25px] xl:text-[30px] p-[2.5vw] gap-[1.5vh] text-violet-300">
                Nos dernières réalisations <br />
                <span className="text-[75%] text-center italic text-zinc-100">
                  Cliquez sur les projets pour
                  <br /> en avoir un aperçu
                </span>
              </div>
            </div>
          </div>
          {/* Description numero 1  */}
          {/* Description numero 1  */}
          {/* Description numero 1  */}
          <div
            className={`${bgMobilier} h-[300px] w-[300px] md:h-[400px] md:w-[400px] lg:h-[350px] lg:w-[350px] xl:h-[500px] xl:w-[500px] rounded-[100%] border border-zinc-100 flex items-center justify-center `}
          >
            <div className="text-center relative flex flex-col items-center justify-center ">
              <div className="text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] text-zinc-900">
                {" "}
                DL DESIGN{" "}
              </div>
              <div className="flex items-center flex-col text-[15px] md:text-[20px] lg:text-[25px] xl:text-[35px] p-[2.5vw] gap-[1.5vh]">
                Site Vitrine <br />
                <span className="text-[75%] text-center italic">
                  Mise en avant du travail d'un <br /> home designer
                </span>
              </div>
            </div>
          </div>

          {/* Description numero 2  */}
          {/* Description numero 2  */}
          {/* Description numero 2  */}
          <div
            className={`${bgAmirel} bg-[#b89e88 h-[300px] w-[300px] md:h-[400px] md:w-[400px] lg:h-[350px] lg:w-[350px] xl:h-[500px] xl:w-[500px] rounded-[100%] border border-zinc-100 flex items-center justify-center `}
          >
            <div className="text-center relative flex flex-col items-center justify-center ">
              <div className="text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] text-zinc-900 ">
                {" "}
                AMIREL{" "}
              </div>
              <div className="flex items-center flex-col text-[15px] md:text-[20px] lg:text-[25px] xl:text-[35px] p-[2.5vw] gap-[1.5vh]">
                E-Commerce <br />
                <span className="text-[75%] text-center italic">
                  Boutique en ligne de produits <br /> naturels d'exception{" "}
                </span>
              </div>
            </div>
          </div>
          {/* Description numero 3  */}
          {/* Description numero 3  */}
          {/* Description numero 3  */}
          <div
            className={`${bgBobamazing} h-[300px] w-[300px] md:h-[400px] md:w-[400px] lg:h-[350px] lg:w-[350px] xl:h-[500px] xl:w-[500px] rounded-[100%] border border-zinc-100 flex items-center justify-center `}
          >
            <div className="text-center relative flex flex-col items-center justify-center ">
              <div className="text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] text-zinc-900">
                {" "}
                BOBAMAZING
              </div>
              <div className="flex items-center flex-col text-[15px] md:text-[20px] lg:text-[25px] xl:text-[35px] p-[2.5vw] gap-[1.5vh] ">
                Site Vitrine <br />
                <span className="text-[75%] text-center italic">
                  Présentation d'un bubble <br /> tea shop
                </span>
              </div>
            </div>
          </div>
          {/* Button numero 1  */}
          {/* Button numero 1  */}
          <div className=" flex items-center justify-center -mt-[80px] lg:mt-0 lg:-ml-[50px] relative  lg:flex-col   ">
            <div
              className="flex justify-center text-center items-center lg:-ml-[50px] lg:w-[100px] lg:h-[100px] h-[80px] w-[80px] rounded-full  text-#b89e88 bg-white hover:border-zinc-100 hover:bg-black hover:text-[#a5b6aa] border  text-[#a5b6aa] border-[#759979]  relative cursor-pointer"
              onClick={() => {
                setBgMobilier("bg-[#a5b6aa]");
                setBgAmirel("hidden");
                setBgBobamazing("hidden");
                setBgNeutral("hidden");
                setBgMockup(mobilier);
              }}
            >
              {" "}
              DL DESIGN
            </div>{" "}
            {/* Button numero 2  */}
            {/* Button numero 2  */}
            <div
              className="flex justify-center items-center mt-[40px] lg:mt-0  lg:w-[100px] lg:h-[100px] h-[80px] w-[80px] rounded-full  border bg-white hover:bg-black hover:border-zinc-100 border-[#b89e88] text-[#b89e88]    relative cursor-pointer"
              onClick={() => {
                setBgAmirel("bg-[#b89e88]");
                setBgMobilier("hidden");
                setBgBobamazing("hidden");
                setBgNeutral("hidden");
                setBgMockup(amirel);
              }}
            >
              AMIREL
            </div>{" "}
            {/* Button numero 3  */}
            {/* Button numero 3  */}
            <div
              className={`flex justify-center items-center lg:-ml-[50px] lg:w-[100px] lg:h-[100px] h-[80px] w-[80px] rounded-full bg-white hover:bg-black hover:border-zinc-100 text-[#fec655] border border-[#fec655]  relative cursor-pointer`}
              onClick={() => {
                setBgBobamazing("bg-[#fec655]");
                setBgMobilier("hidden");
                setBgAmirel("hidden");
                setBgNeutral("hidden");
                setBgMockup(bobamazing);
              }}
            >
              {" "}
              BOBA
            </div>{" "}
          </div>
        </div>{" "}
        {/* DIV AFFICHAGE PROJECT PRINCIPALE */}
        {/* DIV AFFICHAGE PROJECT PRINCIPALE */}
        {/* DIV AFFICHAGE PROJECT PRINCIPALE */}
        <AnimationOnScroll animateIn="animate__fadeInRight">
          <div className="  w-full flex justify-center items-center">
            <img
              src={`${bgMockup}`}
              className="lg:w-[75%] lg:ml-[15vw]"
              alt="mockup site réalisations"
            />
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default Realisations;
