import React from "react";
import TypeWriterEffect from "react-typewriter-effect";
import { Link } from "react-scroll";
import "../components/barre.css";

import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import brain from "../assets/brain.png";
import cleancode from "../assets/cleancode.png";
import lampe from "../assets/lampe.png";
import reglage from "../assets/reglage.png";
import sablier from "../assets/sablier.png";
import update from "../assets/update.png";
import splash from "../assets/splash.jpg";
import mockupLogo from "../assets/mockup-white-transparent.png";

const Hero = () => {
  return (
    <div id="hero" className=" bg-black ">
      <div className=" w-full flex flex-col ">
        <div className="z-10 w-full flex flex-col p-[10vw] gap-[5vh] pt-[15vh]">
          {" "}
          <div>
            <div className="sm:text-[60px] text-[50px] lg:text-[90px] text-violet-300 flex">
              EFFI.
            </div>
          </div>
          <div className="text-[40] sm:text-[50] lg:text-[60px] text-blue-50">
            <TypeWriterEffect
              startDelay={0}
              cursorColor="rgb(196 181 253)"
              multiText={["Paris & Dubai", "Efficience & Efficacité"]}
              multiTextDelay={8000}
              typeSpeed={50}
            />
          </div>
          <h1 className="hidden"> Trust EFFI to make the best WEBSITE </h1>
          <h3 className=" text-blue-50">
            <span className="text-violet-300">Agence </span>{" "}
            <span className="text-violet-300">digitale</span>
            <span> alliant </span> <span> modernité </span> <span> et </span>{" "}
            <span> innovations. </span> <br /> <span> Orientés </span>{" "}
            <span>vers </span> <span> l'avenir, </span> <span> nous </span>{" "}
            <span> réalisons</span> <span> des </span>
            <span className="text-violet-300"> sites </span>{" "}
            <span className="text-violet-300"> web </span> <span>adaptés</span>{" "}
            <span> à </span>
            <span>chacun</span> <span> de </span> <span>vos</span>{" "}
            <span>besoins.</span> <br /> <span>Également</span>{" "}
            <span>spécialisée</span> <span>dans</span>{" "}
            <span>l'intégration</span>{" "}
            <span className="text-violet-300">Web </span>{" "}
            <span className="text-violet-300"> 3.0,</span> <br />{" "}
            <span className="text-violet-300">EFFI</span> <span>propose</span>{" "}
            <span>son</span> <span>expertise</span> <span>dans</span>{" "}
            <span>le</span> <span>développement</span>{" "}
            <span>d'applications</span> <span>décentralisées.</span> <br />
          </h3>
          <div className="h-[10vh] w-full flex justify-center items-center">
            <Link spy={true} smooth={true} duration={500} to="services">
              <button className=" w-[250px] h-[60px] border rounded-full flex overflow-hidden overflow-x-hidden justify-center items-center  text-zinc-100 hvr-bounce-to-right border-violet-300 ">
                {" "}
                Découvrez nos services{" "}
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center  ">
        <img
          src={splash}
          className="  xl:h-[1000px] xl:w-[2000px]   "
          alt="Plage Beach Sand Black"
        />

        <div className="absolute  w-full flex flex-col  justify-center items-center text-center text-zinc-100 text-[15px] sm:text-[20px] md:text-[30px] lg:text-[40px] xl:text-[55px]">
          Trust EFFI
          <AnimationOnScroll animateIn="animate__fadeInUp">
            <div className="text-[125%] text-violet-300">
              {" "}
              Let's make your business grow <br />{" "}
            </div>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 justify-items-center pt-[5vh] gap-[2.5vh] pr-[15vh] pl-[15vh] pb-[5vh] m-auto">
        <AnimationOnScroll animateIn="animate__backInUp">
          <div className="w-[250px] h-[125px]   flex flex-col justify-center items-center">
            <div className="text-violet-300 text-[40px]  "> Efficience </div>
            <div className="text-zinc-100 text-[20px]"> Performance </div>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__backInUp">
          <div className="w-[250px] h-[125px]   flex flex-col justify-center items-center">
            <div className="text-violet-300 text-[40px]  "> Excellence </div>
            <div className="text-zinc-100 text-[20px]"> Expérience</div>
          </div>{" "}
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__backInUp">
          <div className="w-[250px] h-[125px]   flex flex-col justify-center items-center">
            <div className="text-violet-300 text-[40px]  "> Efficacité </div>
            <div className="text-zinc-100 text-[20px]"> Rapidité </div>
          </div>
        </AnimationOnScroll>
      </div>
      {/* GRID - 6 IDEAS ? */}
      <div className="w-full grid grid-cols-1 justify-center items-center text-zinc-100 mx-auto ">
        <div className="w-[360px] h-[300px]  sm:w-[600px] lg:w-[800px]  xl:w-[1200px] m-auto mb-[7.5vh] flex flex-col items-center justify-center">
          {" "}
          <div className="h-[33%] flex gap-3 ">
            {" "}
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <div className="flex justify-center items-center w-[180px] h-[150px]  lg:w-[600px]   text-[12.5px] lg:text-[15px] text-right lg:gap-6  gap-2 ">
                {" "}
                <img
                  src={sablier}
                  className="w-8 h-8 lg:w-12 lg:h-12"
                  alt=""
                />{" "}
                <div className="h-full w-[150px] sm:w-[200px] flex items-center justify-end">
                  Rapidité d'exécution <br />
                  Respect des délais
                </div>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <div className="w-[180px] h-[150px]  lg:w-[600px]   text-[12.5px] lg:text-[15px] flex items-center text-left justify-center lg:gap-6  gap-2 ">
                {" "}
                <div className="h-full w-[150px] sm:w-[200px] flex items-center justify-start">
                  Efficacité du processus <br />
                  Design thinking
                </div>
                <img
                  src={brain}
                  className="w-8 h-8 lg:w-12 lg:h-12"
                  alt=""
                />{" "}
              </div>
            </AnimationOnScroll>
          </div>
          <div className="h-[33%] flex gap-3">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <div className="w-[180px] h-[150px]  lg:w-[600px]  text-[12.5px] lg:text-[15px] flex items-center text-right justify-center lg:gap-6  gap-2 ">
                {" "}
                <img
                  src={lampe}
                  className="w-8 h-8 lg:w-12 lg:h-12"
                  alt=""
                />{" "}
                <div className="h-full w-[150px] sm:w-[200px] flex items-center justify-end">
                  Excellence produit
                  <br />
                  Idées innovantes
                </div>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <div className="w-[180px] h-[150px]  lg:w-[600px]   text-[12.5px]  lg:text-[15px] text-left flex items-center justify-center  lg:gap-6 gap-2  ">
                {" "}
                <div className="h-full w-[150px] sm:w-[200px] flex items-center justify-start ">
                  Expérience utilisateurs
                  <br />
                  Support technique
                </div>
                <img
                  src={reglage}
                  className="w-8 h-8 lg:w-12 lg:h-12"
                  alt=""
                />{" "}
              </div>
            </AnimationOnScroll>
          </div>
          <div className="h-[33%] flex gap-3">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <div className="w-[180px] h-[150px]  lg:w-[600px]  text-[12.5px] lg:text-[15px] flex items-center text-right justify-center lg:gap-6 gap-2 ">
                {" "}
                <img
                  src={update}
                  className="w-8 h-8 lg:w-12 lg:h-12"
                  alt=""
                />{" "}
                <div className="h-full w-[160px] sm:w-[200px] flex items-center justify-end">
                  Technology trends
                  <br />
                  Meilleure performance
                </div>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <div className="w-[180px] h-[150px]  lg:w-[600px]  text-[12.5px] lg:text-[15px] text-left flex items-center justify-center lg:gap-6 gap-2 ">
                {" "}
                <div className="h-full w-[160px] sm:w-[200px] flex items-center justify-start">
                  Optimisation web
                  <br />
                  Gestion efficiente
                </div>
                <img
                  src={cleancode}
                  className="w-8 h-8 lg:w-12 lg:h-12"
                  alt=""
                />{" "}
              </div>
            </AnimationOnScroll>
          </div>
        </div>

        {/* Partie 2 GRID MODELE EFFI */}
        <div className="grid lg:grid-cols-2 grid-cols-1 w-full   pt-[5vh] xl:p-[5vh]  bg-violet-300 ">
          <AnimationOnScroll animateIn="animate__fadeInUp">
            <div className="flex justify-center items-center h-full ">
              <img
                src={mockupLogo}
                className=" w-[50%] sm:w-[40%] md:w-[30%] lg:w-[60%] xl:w-[50%]"
                alt="Iphone GSM Mobile"
              />
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className=" w-[350px] h-[300px]  sm:w-[500px] lg:h-[400px] m-auto lg:pr-[5vw] xl:pr-0">
              <div className="h-[10%] w-full flex justify-center items-center text-[10px] lg:text-[15px] m-auto  ">
                {" "}
                Le Modèle EFFI{" "}
              </div>
              <div className="h-[20%] w-full  flex justify-center  items-center text-[20px] md:text-[22px] lg:text-[25px] text-black">
                {" "}
                Pourquoi choisir EFFI ?{" "}
              </div>
              <div className="lg:h-[70%] h-[60%] w-full flex justify-center text-[15px] md:text-[18px] lg:text-[20px] text-center  ">
                {" "}
                Notre particularité se trouve dans l'importance que nous
                accordons à la communication avec nos clients. Nous saurons
                écouter vos besoins, vous guider et vous conseiller.
                <br /> Efficience & Efficacité sont nos mots d'ordre.
                <br /> Nous attachons un soin tout particulier à développer
                votre image de marque a travers un site impactant.
              </div>{" "}
            </div>{" "}
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};

export default Hero;
