import React from "react";
import "../index.css";

import CardLaunch from "../components/CardLaunch";
import CardLoupe from "../components/CardLoupe";
import CardGlobe from "../components/CardGlobe";

const Blockchain = () => {
  return (
    <div id="blockchain" className=" bg-black text-center pt-[10vh] ">
      {" "}
      <div className="relative grid grid-cols-1   ">
        <div className=" text-blue-50  lg:text-[2.8vw] text-[8vw] text-center p-[2vh]  ">
          Division <span className="text-gradient "> Blockchain </span>
          <span className="hidden xl:inline">
            spécialisée dans le
            <span className="text-gradient "> Web 3.0</span> <br />
            Conseils et accompagnements <br />
            Transition technologique
          </span>
        </div>

        <div className="relative grid grid-cols-1 xl:grid-cols-3 gap-[3.5vh] p-[5vw] ">
          <div className="relative flex flex-col items-center text-blue-50  ">
            <CardLaunch />
            <h2 className="  text-center p-[1vh]">Lancement de projet </h2>

            <p>
              {" "}
              Développement de votre projet <br /> Tokens, NFT, Apps etc.
            </p>
          </div>
          <div className="relative flex flex-col items-center text-blue-50">
            <CardLoupe />{" "}
            <h2 className=" text-center p-[1vh]">Analyse & Expertise </h2>
            <p>
              {" "}
              Nous analysons vos besoins et definissons vos objectifs ensemble.
            </p>
          </div>
          <div className="relative flex flex-col items-center text-blue-50">
            <CardGlobe /> <h2 className="  text-center p-[1vh]">Flexibilité</h2>
            <p>
              {" "}
              Notre polyvalence nous permet de nous adapter à tous types de
              projets.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blockchain;
